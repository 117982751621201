import type { Cart } from '@integrabeauty/shopify-ajax-api';

/**
 * Returns whether a discount code string is well-formed. This does not guarantee the discount code
 * is valid and in the applicable state. That is why this function is not named isValid.
 *
 * This tolerates any kind of input so as to provide a convenient API. The caller is not obligated
 * to check that the input is a string beforehand. This function exists to avoid that boilerplate.
 * However, callers should be providing values that are strings.
 */
export function isWellFormed(value: any) {
  if (typeof value !== 'string') {
    return false;
  }

  if (value.length === 0) {
    return false;
  }

  // This catches abuse of path parameters
  if (value.includes('/') || value.includes('\\')) {
    return false;
  }

  if (value.includes(' ')) {
    return false;
  }

  const upper = value.toUpperCase();

  // This catches common js bugs in the calling code
  if (upper === 'UNDEFINED' || upper === 'NULL') {
    return false;
  }

  // Mitigate some XSS
  if (isSuspicious(upper)) {
    return false;
  }

  return true;
}

/**
 * Returns normalized array of discount code strings currently applied to the cart.
 *
 * This filters out codes from the cart's discount_codes array property where the applicable value
 * is not true. That is what distinguishes this function from {@link getDiscountCodes}.
 *
 * This normalizes the codes. Shopify does not guarantee the code strings are uppercase. This will
 * uppercase.
 *
 * Tolerates null/undefined cart. Returns an empty array. This is safe to use without checking
 * whether cart is defined.
 *
 * Tolerates invalid discount_codes property. This supports a rare case where the cart was SSRed
 * correctly, but then not fetched. The SSR state is missing the property. In this case an empty
 * array is returned.
 */
export function getApplicableDiscountCodes(cart: Cart) {
  if (!cart) {
    return [];
  }

  if (!Array.isArray(cart.discount_codes)) {
    return [];
  }

  return cart.discount_codes
    .filter(code => code.applicable)
    .map(code => code.code)
    .map(code => code.toUpperCase());
}

/**
 * Returns all codes currently in the cart's discount codes array.
 *
 * This includes applicable as well as inapplicable codes. This inclusion of inapplicable codes is
 * what distinguishes this from the function {@link getApplicableDiscountCodes}.
 *
 * The returned codes are always uppercase. This must uppercase the codes returned because Shopify
 * does not guarantee the codes are upper case. Codes applied at checkout are saved in whatever
 * input case was used. The logic that applies codes while shopping can guarantee the input codes
 * are in uppercase but the logic at checkout cannot because the checkout logic is managed by
 * Shopify.
 *
 * It is possible that the discount_codes array property in the input cart is undefined. Shopify
 * does not support rendering the discount_codes array in liquid. There is a situation where the
 * cart state was server side rendered without discount_codes and then we failed to update the state
 * and therefore failed to set the property dynamically during init. This tolerates that so as to
 * guarantee that an array is always returned.
 */
export function getDiscountCodes(cart: Cart) {
  if (!cart) {
    return [];
  }

  if (!Array.isArray(cart.discount_codes)) {
    return [];
  }

  return cart.discount_codes.map(code => code.code).map(code => code.toUpperCase());
}

/**
 * Given a discount code string, return whether the code looks suspicious, e.g. an XSS attempt.
 */
export function isSuspicious(value: string) {
  return typeof value === 'string' && /javascript/i.test(value);
}
