import { assert } from '@integrabeauty/assert';
import * as ajax from '@integrabeauty/shopify-ajax-api';
import { applyDiscountsHelper } from '../../../lib/cart-apply-discounts-helper.js';
import { isWellFormed } from '../../../lib/cart-discount.js';
import * as CartState from '../../../lib/cart-state.js';
import { Transaction } from '../../../lib/cart-transaction.js';
import { copy } from '../../../lib/object.js';
import { retry } from '../../../lib/promise-retry.js';

/**
 * Adds items to the cart. Emits an event when completed.
 *
 * This fails if the request to add fails, or if the request to get the updated state fails.
 *
 * This must perform multiple http requests due to limitations in Shopify's ajax cart api. The first
 * fetch mutates, and the second fetch gets the updated state. Unfortunately, this means that it is
 * possible for the mutation to succeed but for the subsequent fetch to fail. Callers should be wary
 * of this situation. On some future successful retrieval of server side state, the added items will
 * appear, despite this function emitting an error event. This means, for example, that callers
 * should not assume that failure to add is necessarily a real failure.
 *
 * If a discount is specified to be applied on add, it is applied as a part of the same transaction.
 * However, this also introduces some unwanted complexity. This function does not emit an error if
 * applying the discount fails, because this must preserve client side to server side state
 * fidelity, and the second fetch call happens after the first, and Shopify does not support
 * multi-request transactions (e.g. batched conditional requests). This is no simple way to rollback
 * a partial commit because the rollback itself could also fail as it would be an additional
 * request.
 *
 * The new discount is not applied if it appears as existing. This avoids an unnecessary request.
 * The cart's discount_codes array automatically updates the applicable state of applied discount
 * codes as a result of adding new items. There is no need to reapply to trigger the transition from
 * inapplicable to applicable.
 *
 * At the moment, it is unclear whether this should be fetching cart state prior to applying new
 * discounts. Local cart state is assumed to be accurate, so the existing discount codes should be
 * preserved, so there should not be a need, so this does not perform the additional fetch.
 *
 * @param items the items to add
 * @param discountCodes optional discount codes to attempt to apply to the cart after adding the
 * items
 * @param scenario optional description of the context of the add to cart, e.g. the type of ui
 * element or ux experience, that is used by downstream analytics
 */
export async function addItems(items: ajax.AddItem[], discountCodes?: string[], scenario?: string) {
  const transaction = new Transaction('addItems', items, scenario);

  try {
    await addItemsInternal(transaction, items, discountCodes, scenario);
  } catch (error) {
    // Examine the error to determine if the error is from attempting to add an out of stock product
    // to the cart. In that case, use a more specific error code.
    let errorCode = 'ADD_ITEMS';
    if (ajax.isUnavailableError(error)) {
      errorCode = 'ADD_ITEMS_OOS';
    }

    type Detail = WindowEventMap['cart-update-erred']['detail'];
    const event = new CustomEvent<Detail>('cart-update-erred', {
      detail: {
        code: errorCode,
        error,
        inputs: {
          discount_code: discountCodes,
          items
        },
        scenario,
        transaction
      }
    });
    dispatchEvent(event);
  } finally {
    transaction.complete();
  }
}

async function addItemsInternal(transaction: Transaction, items: ajax.AddItem[],
  discountCodes: string[], scenario: string) {
  assert(items.length > 0, 'At least one item must be specified to add');

  const previous = CartState.read();

  const enrichedItems = items.map(addItemEnrich);
  await ajax.cartAdd(enrichedItems);

  // We ignore the output of cart/add.js for several reasons. One reason is that  we need the full
  // cart state, but add.js output only contains an items property. We cannot safely merge the new
  // items with the local state because multiple changes can happen to a cart in multiple tabs, and
  // because it is not possible to compare new line items to old line items by line item key because
  // we have observed line item key shifting, line item splitting, and other surprises. Another
  // reason is that the response body includes zombie items (0 quantity items) from failed adds.

  // If applying a discount code on add, then we will use the output of that call as the new cart
  // state. If applying the discount fails, we still treat the add to cart as a success, and
  // fallback to fetching the new cart state. We treat failure to apply the discount in this case as
  // non-fatal to add to cart because we have effectively already updated cart state and must
  // reflect that state change even though the discount failed to apply.

  let cart = await applyDiscountsOnAdd(previous, discountCodes || [], scenario);

  if (!cart) {
    cart = await retry(ajax.cartFetch(), 3);
  }

  CartState.write(cart, transaction, scenario);
}

/**
 * Try to apply some discount codes to the cart.
 *
 * Returns null when there was no mutation.
 */
async function applyDiscountsOnAdd(beforeCart: ajax.Cart, discountCodes: string[],
  scenario?: string) {
  // We tolerate pointless calls so as to reduce caller boilerplate

  if (discountCodes.length < 1) {
    return null;
  }

  // If any input discount code is malformed, do nothing. This does not throw.

  for (const code of discountCodes) {
    if (!isWellFormed(code)) {
      const error: any = new Error('Malformed discount code');
      error.scenario = scenario;
      error.discount_codes = discountCodes;
      error.discount_code = code;
      console.warn(error);
      return null;
    }
  }

  // This is currently using local state. There are two modes for local state. There is the initial
  // server side render, and there is the state after init. The states are subtly different. On
  // initial server side render the cart is missing the discount_codes array because that is not
  // accessible to liquid. The cart init logic was changed to do a fetch of cart state on every page
  // load just to get the discount_codes array. Sometimes that fetch fails, leaving the server side
  // rendered state as is, in which case the discount_codes property is undefined. This must account
  // for that. In doing so, opt of out apply on add entirely, as any apply discounts call must
  // specify all codes to apply, including existing codes. As we do not know what the previous codes
  // are when init partially failed, it is better to just skip applying than to unintentionally
  // cause existing codes to drop.

  if (!Array.isArray(beforeCart.discount_codes)) {
    return null;
  }

  // As a part of this function's contract, it returns null to signal that a new cart state was not
  // obtained. Therefore, as tempting as it might be, do not init afterCart to beforeCart.

  let afterCart: ajax.Cart = null;

  const overrides = cart_init.discount_overrides;

  // Try to apply. Post validation is disabled because this is a form of best effort automatic
  // application where errors are routine and not fatal.

  try {
    afterCart = await applyDiscountsHelper(beforeCart, discountCodes, overrides, false);
  } catch (error) {
    console.warn(error);
    // continue
  }

  return afterCart;
}

/**
 * @todo set marketing strategy
 */
function addItemEnrich(input: ajax.AddItem) {
  const output = copy(input);
  output.properties = output.properties || {};
  output.properties._url_path = output.properties._url_path || location.pathname;
  return output;
}
