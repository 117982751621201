/**
 * Tries to resolve a promise up to the given attempt limit.
 *
 * There is no delay between retries.
 *
 * Only the error from the last attempt is thrown.
 *
 * The first attempt counts towards the limit. This means that if the limit is 3 then there will be
 * 3 total attempts, not 4. There is not one normal attempt followed by 3 retries.
 *
 * Each additional attempt is consecutive. Each attempt delays all future attempts. There is no
 * concurrency.
 *
 * This assumes valid input. Behavior is undefined if the promise is not a promise, or the limit is
 * 0 or negative. Behavior is also undefined for promises that never settle.
 */
export async function retry<T>(promise: Promise<T>, limit = 3) {
  let lastError: Error;
  for (let attempts = 0; attempts < limit; attempts++) {
    // A promise will always either resolve or reject. This means we will always either stop
    // iterating and return or catch an error and set lastError and continue until the loop
    // condition evaluates to false.

    try {
      const result = await promise;
      return result;
    } catch (error) {
      lastError = error;
      // continue iterating
    }
  }

  // If we reach here then every promise execution rejected

  throw lastError;
}
