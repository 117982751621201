import { assert } from '@integrabeauty/assert';
import { Cart, isCart } from '@integrabeauty/shopify-ajax-api';
import * as uuid from 'uuid';
import { getApplicableDiscountCodes } from './cart-discount.js';
import type { ProductVariant } from './cart-events.js';
import { Transaction } from './cart-transaction.js';
import { copy as copyObject } from './object.js';

// This library is strongly coupled to the cart init section. Any page that uses functionality that
// uses this library must also include the cart init section.

/**
 * Tracks whether the cart initialized for purposes of observing changes in other tabs.
 */
let cartInitialized = false;

/**
 * Tracks whether there are any active transactions for purposes of ignoring state updates in other
 * tabs.
 *
 * This variable's value is not guaranteed to be accurate at all times due to the complexities of
 * race conditions and errors. This should be treated like a hint.
 */
let activeTransactionCount = 0;

/**
 * Tries to open a channel for sending and receiving cart state changed messages.
 *
 * This function is infallible. There is no need to use try/catch when calling this function. When
 * there is an error, the channel is left undefined and a warning message is logged.
 */
function openChannel() {
  if (window.cart_init && !cart_init.channel && typeof BroadcastChannel === 'function') {
    try {
      cart_init.channel = new BroadcastChannel('cart-state');
      cart_init.channel.addEventListener('message', onMessage);
    } catch (error) {
      console.warn(error);
    }
  }
}

/**
 * Starts listening for cart state changes in other tabs.
 *
 * @param queue an array of events that occurred prior to observing
 */
export function observe(queue: Event[]) {
  for (const event of queue) {
    if (isCartUpdatedEvent(event)) {
      try {
        onCartUpdated(event);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  if (!cartInitialized) {
    addEventListener('cart-updated', onCartUpdated);
  }

  addEventListener('cart-transaction-started', onTransactionStarted);
  addEventListener('cart-transaction-completed', onTransactionCompleted);

  openChannel();
}

/**
 * Listen to all cart updates and capture whether the cart initialized successfully in a module
 * scoped variable. We later check this variable in order to suppress reactions.
 *
 * A cart-updated event is only fired if the transaction was successful, so there is no success
 * boolean to check. We only need to look at the is_initial property which is only set to true by
 * the cart init code.
 */
function onCartUpdated(event: WindowEventMap['cart-updated']) {
  if (event.detail.is_initial) {
    cartInitialized = true;
    removeEventListener('cart-updated', onCartUpdated);
  }
}

function onTransactionStarted(_event: WindowEventMap['cart-transaction-started']) {
  activeTransactionCount++;
}

function onTransactionCompleted(_event: WindowEventMap['cart-transaction-completed']) {
  if (activeTransactionCount > 0) {
    activeTransactionCount--;
  } else {
    // Due to out of order loads with arbitrary delays, this module may start observing in the
    // midst of an active transaction in another tab. Eventually the transaction will complete, but
    // the accompanying increment that should have preceded the decrement was not observed.
  }
}

function onMessage(event: MessageEvent) {
  // If a message was received prior to the cart completing init, or the init failed, then we do not
  // want to react to state change in other tabs. The user has been presented with a visual error,
  // and we do not want to do any other state changes in the current tab, because now nothing is
  // guaranteed to work.

  if (!cartInitialized) {
    return;
  }

  // Ignore messages while a transaction is active in the current tab. This is not foolproof. If a
  // message is received while a transaction is active in another tab, but the transaction started
  // event was not observed because this code did not load in time, then the active transaction
  // count is 0. This is probably rare as this will only happen to an impatient user who starts a
  // transaction in one tab, then opens a new tab and starts a new transaction while the transaction
  // in the other tab is ongoing.

  if (activeTransactionCount > 0) {
    return;
  }

  // Retrieve the data from the message. We do not use a type cast because messages must be
  // untrusted at runtime.

  const cart = event.data;

  // Prove, at runtime, the content is relevant and valid.

  if (!isCart(cart)) {
    return;
  }

  // Start a transaction so as to lock down the ui, then update the cart state. To avoid recursion
  // this specifies that the write should not broadcast.

  const transaction = new Transaction('externalCartUpdate');
  try {
    write(cart, transaction, 'multiple-tabs', false, false);
  } catch (error) {
    console.warn(error);
  } finally {
    transaction.complete();
  }
}

/**
 * Tries to send a message containing cart state to all other tabs.
 *
 * For now, the content of the message is the cart state itself. Cart state is simple and trivially
 * serializable (structured-cloneable) so for now there is no need for a more complex message
 * structure.
 *
 * This function may throw. Not all user agents properly implement postMessage or call postMessage
 * in a scenario where it is allowed (e.g. when in an iframe or other strange context).
 */
function broadcastCartState(cart: Cart) {
  openChannel();
  cart_init.channel?.postMessage(cart);
}

/**
 * Sets the current state of the cart. This is the in memory state that is stored as the value of a
 * global variable, not the server side state.
 *
 * In memory state is PER TAB PER WINDOW/APP. If someone is browsing in multiple tabs concurrently
 * the state may conflict. The cart token corresponds to a server side cart id and is stored in a
 * cookie that is shared among all tabs, so it is possible for multiple tabs to refer to the same
 * cart object.
 *
 * Always use this method to update the state of the global cart variable. Do not update the state
 * of the global cart variable directly.
 *
 * Throws an error if the given cart is not a cart. This also indicates are more insidious problem
 * where there is no longer a guarantee that client side state reflects server site state, so
 * consider some error handling.
 *
 * This saves a copy of the cart so that future updates by reference to the cart object do not
 * modify the global cart state.
 *
 * Emit a cart updated event that contains some of the differences. This emits the updated event
 * even when there are no changes detected.
 *
 * Any update can result in unrelated cart changes. Changing one line's properties could cause line
 * merging. Also, prior state comes from page state, which is per tab, so interactions in multiple
 * tabs can produce strange behavior.
 */
export function write(cart: Cart, transaction: Transaction, scenario?: string, initial = false,
  broadcast = true) {
  const previous = initial ? null : read();

  // Update global state.
  window.cart_init.cart = copy(cart);

  // Prepare for diffing. We group by variant because line keys change in unexpected ways.
  const oldVariants = groupByVariant(previous);
  const newVariants = groupByVariant(cart);

  // For discount code deltas, we only examine deltas in codes that both exist and are in the
  // applicable state in the cart's discount_codes array.

  const prevCodes = getApplicableDiscountCodes(previous);
  const currCodes = getApplicableDiscountCodes(cart);

  // Emit an event with diffs

  type Detail = WindowEventMap['cart-updated']['detail'];
  const event = new CustomEvent<Detail>('cart-updated', {
    detail: {
      cart,
      discount_codes_added: complement(currCodes, prevCodes),
      discount_codes_removed: complement(prevCodes, currCodes),
      event_id: uuid.v4(),
      is_initial: initial,
      previous,
      scenario,
      variants_added: findVariantsAdded(oldVariants, newVariants),
      variants_removed: findVariantsRemoved(oldVariants, newVariants),
      transaction: {
        id: transaction.id,
        name: transaction.name
      }
    }
  });
  dispatchEvent(event);

  // The broadcast to other tabs takes place after the local event emit. This is intentional in case
  // broadcasting to another tab somehow indirectly triggers a sequence of cross tab messages. We
  // want the local state and reaction to same-tab generated events to always take priority over
  // events sourced from other tabs in the case of some kind of unexpected conflict.

  // Only broadcast on state changes after the initial state change. There is some fairly confusing
  // logic behind this. Basically, there is always a cart state update when the page loads, which
  // always results in broadcasting a message. However, that message is not actually useful, as cart
  // state during initialization is not a change as a result of a mutation that took place in the
  // current tab. In another tab not yet open, it will acquire the latest state on its init, and
  // only cares about changes to cart state after init.

  // In addition, only broadcast when the broadcast flag is true. Not all writers want to broadcast.
  // For example, the state change that happens as a result of reacting to an external cart update
  // should not broadcast as this causes infinite recursion (ping ponging).

  if (!initial && broadcast) {
    try {
      broadcastCartState(cart);
    } catch (error) {
      console.warn(error);
    }
  }
}

/**
 * Returns a copy of the global cart state.
 *
 * Throws an error if the global cart object is not a cart object.
 *
 * There is a subtle flaw in the design here. This is a library function common to all modules
 * but this relies on a global declared by the cart init section.
 */
export function read() {
  return copy(cart_init.cart);
}

/**
 * Returns a copy of the input cart. Throws an error when the input cart object is not a cart.
 */
export function copy(cart: Cart) {
  assert(isCart(cart), 'Invalid cart');
  return copyObject(cart);
}

type ProductVariantMap = Record<string, ProductVariant>;

/**
 * Aggregates cart line items by variant. Returns the aggregated variants as an object where each
 * key of the object is a variant id and each value is the corresponding variant object.
 *
 * This does some complex stuff. One key thing to constantly point out is that a variant can appear
 * in more than one line item in a cart. There are several reasons why. Shopify generally refers to
 * this as a variant split. Variant splits happen for reasons such as having different line item
 * properties, or different prices as a result of allocated discounted applications, or because of
 * more rare reasons like product subscriptions. Because we are grouping by variant id, we have to
 * invent some novel ways of combining the different values of the line items containing the same
 * variant.
 *
 * To combine the line item properties, we invent a new property, merged_properties, that contains
 * the properties of each line item. In each merged properties value, there is an array of values.
 * When appending to the values array, we only append unique values (using exact value comparison).
 *
 * There are many downstream concerns. Here is one example. When relaying an add to cart event to a
 * third party analytics system, we sometimes want the product's image url. This function's output
 * is the input to the variants_added array of the cart updated event emitted as a result of a cart
 * write. So we have to embed the image url here in the variant object so that it can be accessed
 * without having to do some kind of later lookup.
 *
 * @param cart the input cart to aggregate
 */
function groupByVariant(cart: Cart) {
  const output: ProductVariantMap = {};

  // It is important to return null here, and not the empty object, so that later functions that
  // use this output can trivially distinguish between transitions from null state and transitions
  // from empty cart state. The transition from null state happens on init.

  if (!cart) {
    return null;
  }

  for (const item of cart.items) {
    const variant = output[item.variant_id.toString()];
    if (variant) {
      // Merge the item's properties into the variant's properties.
      for (const property in item.properties || {}) {
        variant.merged_properties[property] = variant.merged_properties[property] || [];
        const value = item.properties[property];
        if (!variant.merged_properties[property].includes(value)) {
          variant.merged_properties[property].push(value);
        }
      }

      variant.quantity += item.quantity;
    } else {
      // Merge in the initial properties, where each value is a one value array.
      const properties: Record<string, string[]> = {};
      for (const key in item.properties || {}) {
        properties[key] = [item.properties[key]];
      }

      output[item.variant_id.toString()] = {
        image_url: item.image,
        merged_properties: properties,
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        price: item.price,
        product_id: item.product_id,
        product_title: item.product_title,
        product_type: item.product_type,
        quantity: item.quantity,
        sku: item.sku,
        url: item.url,
        variant_id: item.variant_id,
        variant_title: item.variant_title
      };
    }
  }

  return output;
}

/**
 * Returns an array of variant data objects that were added as a result of the write mutation.
 *
 * This tolerates nulls
 *
 * @param c0map variants in old cart state
 * @param c1map variants in new cart state
 * @returns variants added array
 */
function findVariantsAdded(c0map: ProductVariantMap, c1map: ProductVariantMap) {
  // When the cart transitions from the null state, there are no previous variants. This means
  // that all variants in the new state have been added. Transitioning from null can mean multiple
  // things. It can mean either that cart state was initialized on page load, or that cart state
  // was recovered from error. Because of the ambiguity we are not emitting adds for transitions
  // from the null state.

  if (!c0map) {
    return [];
  }

  // Figure out which variants had quantity increases in any of the line items containing those
  // variants. Any variant that had a quantity increase is referred to here as an increment. In
  // addition, look for any variants that are present in the new state but not in the old state,
  // in which case their full quantity is the quantity added.

  const increments: ProductVariantMap = {};
  for (const key in c1map) {
    if (c0map[key]) {
      // In this branch, the variant was present in the old map and is present also in the new map.

      if (c1map[key].quantity > c0map[key].quantity) {
        increments[key] = copyObject(c1map[key]);
        increments[key].quantity = c1map[key].quantity - c0map[key].quantity;
      } else {
        // ignore 0 quantity change and negative quantity change (decrements).
      }
    } else {
      // In this branch, the variant is present in the new map but not in the old map.

      increments[key] = copyObject(c1map[key]);
    }
  }

  return Object.keys(increments).map(key => increments[key]);
}

function findVariantsRemoved(c0map: ProductVariantMap, c1map: ProductVariantMap) {
  if (!c0map) {
    return [];
  }

  const decrements: ProductVariantMap = {};
  for (const key in c0map) {
    if (c1map[key]) {
      if (c1map[key].quantity < c0map[key].quantity) {
        decrements[key] = copyObject(c1map[key]);
        decrements[key].quantity = c0map[key].quantity - c1map[key].quantity;
      } else {
        // ignore 0 quantity change
      }
    } else {
      // if the old variant is not found in the updated cart, all quantity was removed
      decrements[key] = copyObject(c0map[key]);
    }
  }

  return Object.keys(decrements).map(key => decrements[key]);
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

/**
 * Return all values present in array a that are not present in array b.
 *
 * The complement of a set is the set that includes all the elements of the universal set that are
 * not present in the given set. Here, a is the universal set and b is the given set.
 */
function complement(a: string[], b: string[]) {
  assert(Array.isArray(a), 'complement parameter "a" is not an array');
  assert(Array.isArray(b), 'complement parameter "b" is not an array');
  return a.filter(value => !b.includes(value));
}
