import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * This is a lower level element that renders an notify me button to register for an out of stock
 * notification to a particular variant id.
 *
 * This uses the Back In Stock app installed on Shopify.
 *
 * @deprecated this element should not have been implemented, add support for out of stock feature
 * to the other button element and then remove this element
 */
class AddToCartNotifyMeButton extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Customer email.
     */
    email: string;

    /**
     * Stretch CTA button to full width.
     */
    full: string;

    /**
     * The ID of the product.
     */
    productId: string;

    /**
     * The title of the product.
     */
    productTitle: string;

    /**
     * The URL of the product page.
     */
    productUrl: string;

    /**
     * The sku of the product.
     */
    sku: string;

    /**
     * The ID of variant.
     */
    variantId: string;

    /**
     * The title of the variant.
     */
    variantTitle: string;
  };

  public shadowRoot!: ShadowRoot;
  private onShowFormButtonClickedBound = this.onShowFormButtonClicked.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('.show-form-button');
    button.addEventListener('click', this.onShowFormButtonClickedBound);
  }

  public disconnectedCallback() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('.show-form-button');
    button?.removeEventListener('click', this.onShowFormButtonClickedBound);
  }

  onShowFormButtonClicked(_event: MouseEvent) {
    type Detail = NotifyMeClickedEvent['detail'];
    const notifyEvent = new CustomEvent<Detail>('notify-me-clicked', {
      cancelable: false,
      detail: {
        email: this.dataset.email,
        productId: parseInt(this.dataset.productId, 10),
        productTitle: this.dataset.productTitle,
        productUrl: this.dataset.productUrl,
        sku: this.dataset.sku,
        variantId: parseInt(this.dataset.variantId, 10),
        variantTitle: this.dataset.variantTitle
      }
    });
    dispatchEvent(notifyEvent);
  }
}

/**
 * This is fired when the "Email when available" link is clicked.
 *
 * This event is used as a signal to open the modal that allows the user to sign up for an email
 * notification when the product becomes available.
 */
type NotifyMeClickedEvent = CustomEvent<{
  /**
   * The user email
   */
  email: string;

  /**
   * The ID of the product
   */
  productId: number;

  /**
   * The title of the product
   */
  productTitle: string;

  /**
   * The URL of the product page
   */
  productUrl: string;

  /**
   * The sku of the product
   */
  sku: string;

  /**
   * The ID of variant
   */
  variantId: number;

  /**
   * The title of the variant
   */
  variantTitle: string;
}>;

declare global {
  interface WindowEventMap {
    'notify-me-clicked': NotifyMeClickedEvent;
  }

  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    'add-to-cart-notify-me-button': AddToCartNotifyMeButton;
  }
}

if (!customElements.get('add-to-cart-notify-me-button')) {
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  customElements.define('add-to-cart-notify-me-button', AddToCartNotifyMeButton);
}
