/**
 * This is a placeholder element that carries data properties, it has no functionality, just for
 * semantic mark-up.
 */
class BundleOption extends HTMLElement {
  readonly dataset!: {
    /**
     * The name associated either automatically or manually configured when creating a bundle via
     * the Shopify Bundle App
     */
    name: string;

    /**
     * The position of the bundle option in the product's options array
     */
    position: string;
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'bundle-option': BundleOption;
  }
}

if (!customElements.get('bundle-option')) {
  customElements.define('bundle-option', BundleOption);
}
