type StartedEvent = CustomEvent<Transaction>;
type CompletedEvent = CustomEvent<Transaction>;

/**
 * Occurs when multiple transactions are active. This should never happen. This indicates that a
 * higher layer is misusing the service.
 */
type ConcurrencyDetectedEvent = CustomEvent<{
  /** The state of the timeline at the time the concurrency was detected */
  timeline: any;

  transaction: Transaction;
}>;

declare global {
  /**
   * The number of currently active cart service transactions.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var cart_active_transactions_count: number;

  /**
   * Number of cart operations
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var cart_op_count: number;

  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var cart_op_timeline: Record<string, any>[];

  interface WindowEventMap {
    'cart-transaction-completed': CompletedEvent;
    'cart-transaction-concurrency-detected': ConcurrencyDetectedEvent;
    'cart-transaction-started': StartedEvent;
  }
}

/**
 * A transaction represents an action carried out by the cart service. For example, an add to cart
 * transaction or a remove from cart transaction.
 */
export class Transaction {
  /** The arguments to the start of the transaction */
  public args: any[];

  /** The transaction start time in milliseconds */
  public epoch: number;
  /** Transaction unique global id */
  public id: number;
  /** Transaction name, e.g. function name */
  public name: string;

  /** Timeout timer id */
  private timer: ReturnType<Window['setTimeout']>;

  public did_timeout: boolean;

  /**
   * Start a cart service transaction.
   */
  constructor(name: string, ...args: any[]) {
    window.cart_op_count = window.cart_op_count || 0;
    window.cart_op_timeline = window.cart_op_timeline || [];

    this.name = name;
    this.args = args;
    this.epoch = Date.now();

    // Increment global transaction id then reserve the current id.

    window.cart_op_count++;
    this.id = window.cart_op_count;

    this.start();
  }

  private start() {
    window.cart_active_transactions_count =
      Number.isInteger(window.cart_active_transactions_count) ?
        window.cart_active_transactions_count + 1 :
        1;

    if (window.cart_active_transactions_count > 1) {
      type Detail = ConcurrencyDetectedEvent['detail'];
      const event = new CustomEvent<Detail>('cart-transaction-concurrency-detected', {
        detail: {
          transaction: this,
          timeline: [...window.cart_op_timeline]
        }
      });
      dispatchEvent(event);
    }

    append({ epoch: this.epoch, id: this.id, name: this.name, type: 'start' });
    this.timer = window.setTimeout(this.complete.bind(this), 30 * 1000, true);

    type Detail = StartedEvent['detail'];
    const event = new CustomEvent<Detail>('cart-transaction-started', { detail: this });
    dispatchEvent(event);
  }

  /**
   * Called when an transaction completes.
   *
   * Appends the transaction to the transaction history log.
   *
   * Emits a transaction completed event. Various transactions should call this when completed to
   * signal the completion to listeners.
   *
   * This is called either manually whenever the transaction completes, or automatically, if the
   * transaction did not call complete quickly enough (e.g. because of an error, omission, or a
   * genuinely long operation that timed out).
   *
   * Transactions should be short-lived. Transactions are automatically considered complete after a
   * certain amount of time so as to guarantee completion. Higher layer interfaces rely on
   * transaction state to lock interfaces for periods of time. Locking must be definite.
   */
  public complete(didTimeOut = false) {
    clearTimeout(this.timer);
    this.timer = undefined;

    this.did_timeout = didTimeOut;

    append({
      epoch: Date.now(),
      id: this.id,
      name: this.name,
      timeout: didTimeOut,
      type: 'end'
    });

    if (Number.isInteger(window.cart_active_transactions_count)) {
      window.cart_active_transactions_count--;
    } else {
      window.cart_active_transactions_count = 0;
    }

    type Detail = CompletedEvent['detail'];
    const event = new CustomEvent<Detail>('cart-transaction-completed', { detail: this });
    dispatchEvent(event);
  }
}

function append(entry: Record<string, any>) {
  window.cart_op_timeline.push(entry);

  const capacity = 50;
  window.cart_op_timeline.splice(0, Math.max(window.cart_op_timeline.length - capacity, 0));
}
