import type { CustomElement } from '@integrabeauty/custom-elements';
import * as ajax from '@integrabeauty/shopify-ajax-api';
import { addItems } from './add-items.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders an add to cart button.
 *
 * @todo rename slot add-to-cart-button-data-variant to "variant"
 */
class AddToCartButton extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-update'];
  }

  readonly dataset!: {
    buttonText: string;

    country: string;

    /**
     * set to true to prevent side cart open on storefront call complete
     */
    disableSideCartAction: 'false' | 'true';

    /**
     * If specified, will attempt to apply discount code after variants are added.
     */
    discountCode: string;

    /**
     * Boolean flag indicating the button should render to the full width of its parent container.
     */
    full: 'false' | 'true';

    /**
     * The name of the Shopify template used by the page that rendered this element.
     *
     * Optional.
     *
     * This affects certain behavior such as whether the side cart is opened on click.
     */
    templateName: string;

    /**
     * Set this attribute to a value different than its previous value to cause this element to
     * re-render itself. Typically we use the current epoch as the value (e.g. `Date.now()`).
     */
    update: string;

    /**
     * Boolean flag represented as string to display the button styled for the inline cart upsell
     */
    upsellCartVariant: 'false' | 'true';

    /**
     * Boolean flag represented as string to display the button styled for the upsell modal
     */
    upsellModalVariant: 'false' | 'true';

    /**
     * Variant title
     */
    variantTitle: string;
  };

  public shadowRoot!: ShadowRoot;
  private cartInitSucceeded = false;
  private onClickBound = this.onClick.bind(this);
  private onCartTransactionStartedBound = this.onCartTransactionStarted.bind(this);
  private onCartTransactionEndedBound = this.onCartTransactionEnded.bind(this);
  private onCartUpdatedBound = this.onCartUpdated.bind(this);
  private onSlotChangeBound = this.onSlotChange.bind(this);
  private items?: ajax.AddItem[];

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const variant = this.shadowRoot.querySelector('slot[name="add-to-cart-button-data-variant"]');
    variant.addEventListener('slotchange', this.onSlotChangeBound);

    this.items = this.populateItems();

    const button = this.shadowRoot.querySelector('button');
    button.addEventListener('click', this.onClickBound);
    button.setAttribute('aria-label', `Add ${this.dataset.variantTitle} to cart`);

    this.updateButtonText();

    for (const event of add_to_cart_elements_event_queue) {
      if (isCartUpdatedEvent(event)) {
        try {
          this.onCartUpdatedBound(event);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    addEventListener('cart-updated', this.onCartUpdatedBound);
    addEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    addEventListener('cart-transaction-completed', this.onCartTransactionEndedBound);
  }

  public disconnectedCallback() {
    const variant = this.shadowRoot.querySelector('slot[name="add-to-cart-button-data-variant"]');
    variant?.removeEventListener('slotchange', this.onSlotChangeBound);

    const button = this.shadowRoot.querySelector('button');
    button?.removeEventListener('click', this.onClickBound);

    removeEventListener('cart-updated', this.onCartUpdatedBound);
    removeEventListener('cart-transaction-started', this.onCartTransactionStartedBound);
    removeEventListener('cart-transaction-completed', this.onCartTransactionEndedBound);
  }

  public attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
    if (name === 'data-update') {
      this.items = this.populateItems();
      this.updateButtonText();
    }
  }

  private onSlotChange(_event: Event) {
    // this event is called when the slot is changed, but also when the element is destroyed
    // this causes race conditions in exotic browsers, i.e. neither the slot contents, nor
    // the shadowRoot contents are guaranteed to exist here

    // only populate items and render if we have the slot properly filled
    const variant = this.querySelector('[slot="add-to-cart-button-data-variant"]');
    if (variant) {
      this.items = this.populateItems();
      this.updateButtonText();
    }
  }

  private renderDisabledState() {
    const button = this.shadowRoot.querySelector('button');
    button.setAttribute('aria-label', `Add ${this.dataset.variantTitle} to cart`);
    button.disabled = true;
    button.classList.remove('loading');
  }

  private renderEnabledState() {
    const button = this.shadowRoot.querySelector('button');
    button.setAttribute('aria-label', `Add ${this.dataset.variantTitle} to cart`);
    button.disabled = false;
    button.classList.remove('loading');
  }

  private renderLoadingState() {
    this.shadowRoot.querySelector('button').classList.add('loading');
    this.shadowRoot.querySelector('.title').classList.add('hidden');
    const loadingEl = this.dataset.upsellModalVariant === 'true' ?
      this.shadowRoot.querySelector('.loading-upsell-modal-variant') :
      this.shadowRoot.querySelector('loading-element');

    loadingEl.classList.remove('hidden');
  }

  private renderLoadedState() {
    const button = this.shadowRoot.querySelector('button');
    button.setAttribute('aria-label', `Add ${this.dataset.variantTitle} to cart`);
    button.disabled = true;
    button.classList.add('loaded');
    this.dataset.buttonText = 'Added To Bag!';
    this.dataset.update = Date.now().toString();
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    if (event.detail.is_initial && !this.cartInitSucceeded) {
      this.cartInitSucceeded = true;
      this.renderEnabledState();
    }
  }

  private onCartTransactionStarted(_event: WindowEventMap['cart-transaction-started']) {
    this.renderDisabledState();
  }

  private onCartTransactionEnded(_event: WindowEventMap['cart-transaction-completed']) {
    if (this.cartInitSucceeded) {
      const button = this.shadowRoot.querySelector('button');
      if (button.classList.contains('loaded')) {
        this.renderLoadedState();
      } else {
        this.renderEnabledState();
      }
    }

    // make sure the button is not in a loading state
    this.shadowRoot.querySelector('.title')?.classList.remove('hidden');

    const loadingEl = this.dataset.upsellModalVariant === 'true' ?
      this.shadowRoot.querySelector('.loading-upsell-modal-variant') :
      this.shadowRoot.querySelector('loading-element');
    loadingEl?.classList.add('hidden');
  }

  /**
   * This function could be designed to load the addItems function lazily. Previously, when dealing
   * with some large webpack chunks, the function was optimized to do so, as this kept the size of
   * the chunk containing this element definition small. However, the problem with that approach is
   * that it determines whether an add to cart succeeds based on whether the additional webpack
   * chunk load request to load the add to cart function js works. It turns out that webpack chunks
   * routinely fail to load in certain cases. Therefore we must be judicious about which
   * functionality we decide to load lazily. I have decided now that add to cart is a critical user
   * action that should not fail because of a webpack chunk load error. Therefore, we statically
   * import the addItems function into this module instead of dynamically importing the function
   * here. The tradeoff is that the chunk is now larger, so whatever code imports the add to cart
   * button module itself needs to be aware of its larger size. At the moment, this is actually
   * fine, because it turns out that this element is loaded as its own chunk and is a relatively
   * tiny module.
   */
  private onClick(event: MouseEvent) {
    const button = <HTMLButtonElement>event.target;
    if (button.disabled) {
      return;
    }

    if (!Array.isArray(this.items) || this.items.length === 0) {
      throw new Error('add to cart bundle clicked but lines prop is not initialized or empty');
    }

    this.renderLoadingState();

    const discountCodes = [];
    if (this.dataset.discountCode && this.dataset.discountCode !== 'undefined' &&
      this.dataset.discountCode !== 'null') {
      discountCodes.push(this.dataset.discountCode);
    }

    addItems(this.items, discountCodes, 'button').catch(console.warn);

    if (this.dataset.disableSideCartAction === 'true') {
      return;
    }

    // TODO: this needs to be redesigned. we already have an event listener for this event. we
    // prefer to have persistent event listeners. this may not be working as intended and is
    // confusing.

    const onCartTransactionCompleted = (_event: WindowEventMap['cart-transaction-completed']) => {
      removeEventListener('cart-transaction-completed', onCartTransactionCompleted);

      // Show the cart when the call ends. This should happen on every page except for the cart
      // page, which has no side cart.

      if (this.dataset.upsellModalVariant === 'true') {
        this.renderEnabledState();
      } else {
        dispatchEvent(new Event('cart-open-requested'));
      }
    };

    addEventListener('cart-transaction-completed', onCartTransactionCompleted);
  }

  private populateItems() {
    const items: ajax.AddItem[] = [];

    type VariantElement = HTMLElementTagNameMap['variant-element'];
    const variants = this.querySelectorAll<VariantElement>(
      '[slot="add-to-cart-button-data-variant"]');

    for (const variantElement of variants) {
      if (!variantElement.dataset.id) {
        continue;
      }

      const item: ajax.AddItem = {
        id: variantElement.dataset.id,
        properties: {},
        quantity: parseInt(variantElement.dataset.quantity, 10)
      };

      const attributes = variantElement.querySelectorAll('custom-attribute');
      for (const attribute of attributes) {
        if (attribute.dataset.key === 'selling_plan') {
          item.selling_plan = attribute.dataset.value;
        } else {
          item.properties[attribute.dataset.key] = attribute.dataset.value;
        }
      }

      items.push(item);
    }

    return items;
  }

  private updateButtonText() {
    const button = this.shadowRoot.querySelector('button');
    button.setAttribute('aria-label', `Add ${this.dataset.variantTitle} to cart`);

    const buttonTitle = this.shadowRoot.querySelector('.title');
    if (this.dataset.buttonText) {
      buttonTitle.textContent = this.dataset.buttonText;
    } else if (this.items.some(line => line.selling_plan)) {
      buttonTitle.textContent = 'Get it Now!';
    } else {
      buttonTitle.textContent = 'Add To Bag';
    }
  }
}

function isCartUpdatedEvent(value: any): value is WindowEventMap['cart-updated'] {
  return value?.type === 'cart-updated';
}

declare global {
  interface HTMLElementTagNameMap {
    'add-to-cart-button': AddToCartButton;
  }
}

if (!customElements.get('add-to-cart-button')) {
  customElements.define('add-to-cart-button', AddToCartButton);
}
