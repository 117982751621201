import * as ajax from '@integrabeauty/shopify-ajax-api';
import { getApplicableDiscountCodes, getDiscountCodes, isWellFormed } from './cart-discount.js';
import { retry } from './promise-retry.js';

/**
 * Updates a cart's discount codes. This uses a specially enabled feature on the lange Shopify shop
 * that enables the use of the cart update ajax api to modify the cart's discounts. The special
 * feature enables the use of a discount property in the update call.
 *
 * New codes are partially validated before the http request is sent. If any new code is invalid,
 * this throws an error immediately.
 *
 * This will send an update request even if the discount codes have not changed. This does not check
 * whether sending the request is pointless. One of the reasons why this check is not performed is
 * because sometimes callers want to call this function even when it seems pointless because of the
 * risk that client side state is out of sync with server side state. That kind of thing can happen
 * for example when browsing the site in multiple tabs, because client side state is largely
 * managed per tab, so one tab may naively update discounts without awareness of changes made in
 * other tabs. While we attempt to keep state synchronized across tabs, there is no guarantee.
 *
 * The special discount property of the update request body has no documentation. We figured out
 * that it works similar to the checkout links discount url parameter. Multiple codes can be
 * specified as a comma separated value. This also means that codes should not contain commas. We
 * also figured out that it overwrites all discounts. This does not just add new discounts. That is
 * why this requires both the set of existing codes and the new codes.
 *
 * @param cart the state of the cart prior to update
 * @param newCodes the codes that should be applied to the cart in addition to the existing codes
 * @param overrides discount overrides, this is expected to always be defined even if there are no
 * overrides
 * @param postValidate whether to check if the new codes were actually applied after the update
 *
 * @see https://help.shopify.com/en/manual/discounts/managing-discount-codes
 */
export async function applyDiscountsHelper(cart: ajax.Cart, newCodes: string[],
  overrides: DiscountOverride[], postValidate = true) {
  validateCodesBeforeUpdate(newCodes);

  const oldUppers = getCurrentCodes(cart, newCodes.length);
  const newUppers = newCodes.map(code => code.toUpperCase());

  const retainedOldUppers = oldUppers.filter(code =>
    !overrides.some(override =>
      override.old_code === code && newUppers.includes(override.new_code)));

  const mergedUppers = new Set<string>([...retainedOldUppers, ...newUppers]);
  const mergedCodes = [...mergedUppers];

  // Now we have an array of merged uppercase discount codes to send in the update. We want to check
  // if there no point to sending this update because it will not result in a change

  const discount = mergedCodes.join(',');
  await ajax.cartUpdate({ discount });
  const updatedCart = await retry(ajax.cartFetch(), 3);

  // Only validate if post validation requested. Validation is performed by default but there are
  // some use cases where discounts are applied even in applicable situations, such as when the cart
  // is empty (the discount is applied but if you look at the discount object in the discount_codes
  // array the applicable property is false). By only validating if requested we avoid generating
  // routine validation errors.

  if (postValidate) {
    validateCodesAfterUpdate(updatedCart, newCodes);
  }

  return updatedCart;
}

/**
 * Obtain the existing discount codes. Generally, we want to retain all codes, including currently
 * inapplicable codes, as those codes may become applicable later and have been deemed applied even
 * though they have no effect. However, retaining all codes leads to the possible accumulation of
 * several inapplicable codes. This leads into a poorly documented issue. Once there are about 5 or
 * 6 codes applied, in either the applicable or inapplicable state, future applications fail without
 * regard to code applicability.
 *
 * Therefore, this needs to take into account the total number of discount codes that will be sent
 * as a part of a cart update. This is why the new codes array is also an input to this function
 * that has only to do with old codes.
 *
 * This is a hacky rushed fix that needs to be revisited. Expired codes stick around. Typo codes
 * stick around. Once we reach more than 5 codes (in any state), all future applications, even of
 * valid codes, incorrectly fail to apply. So in this case, we have to filter down the list. The
 * quickest thing we can do is just drop codes that are applied but in the inapplicable state. We
 * prefer not to do this because we want to support visitors who apply a code, it becomes applied
 * but inapplicable, then add a product, and it becomes applicable.
 *
 * This hacky fix is not perfect because it is not deduplicating, this is an inaccurate and quick
 * fix for "several" issues, not all of the issues stemming from bad codes sticking around.
 *
 * There is a limitation on the number of discount codes that can be applied to the same order,
 * including codes in the inapplicable state. According to Shopify, "Customers can use a maximum of
 * five product or order discount codes and one shipping discount code on the same order."
 *
 * @see https://help.shopify.com/en/manual/discounts/combining-discounts/discount-combinations#limitations
 */
function getCurrentCodes(cart: ajax.Cart, newCodesCount: number) {
  const currentCodes = Array.isArray(cart.discount_codes) ? cart.discount_codes : [];

  // We test against what would be the total number of applied codes in determining which codes
  // should be included in the output. If adding the new codes would exceed the threshold, then we
  // try our best to trim the number of currently applied codes down to below the threshold. Since
  // we cannot tell which discount codes are shipping discount codes, we use the threshold of 5
  // instead of 6.

  // There is currently no reliable additional way of choosing which discount codes to remove. There
  // is no clear documentation on the order of the codes in the array. So we have to choose an
  // arbitrary method of ejecting some codes. For now, this basically just gets rid of any codes
  // that are inapplicable. That will most likely remove typos and expired codes. Most visitors will
  // not discern the missing codes.

  // This does not guarantee that the output is reduced to below the threshold. If there are 5
  // applicable codes, then no codes will be removed, and the 6th application can still fail.

  // This is not trying to get down to just below the threshold. This does a whole sweep. We might
  // want to change this to remove inapplicable codes up until the threshold is no longer exceeded.

  // Drop all inapplicable codes by keeping around only those older codes that are applicable. Try
  // to retain inapplicable shipping codes.

  if (currentCodes.length + newCodesCount > 5) {
    return currentCodes
      .filter(code => code.applicable || /ship/i.test(code.code))
      .map(code => code.code)
      .map(code => code.toUpperCase());
  }

  return getDiscountCodes(cart);
}

function validateCodesBeforeUpdate(newCodes: string[]) {
  if (newCodes.length < 1) {
    throw new Error('At least one discount code must be specified');
  }

  // If any one of the input codes is invalid, reject with a specific error message that mentions
  // the code. It is important to be explicit here because this error may be visible to users.

  for (const code of newCodes) {
    if (!isWellFormed(code)) {
      const error: any = new Error(`Invalid discount code ${code}`);
      error.discount_code = code;
      error.discount_codes = newCodes;
      throw error;
    }
  }
}

/**
 * When validating whether the codes applied, we only validate whether new codes applied. We do not
 * validate whether existing codes applied, because it possible that applying a new code caused a
 * previous code to be removed, because the new code replaces the existing code, for example,
 * because the new code offers a better discount.
 *
 * If a code was applied but in the inapplicable state, then we treat the add as a failure, even the
 * code the code persists in the discount_codes array. The rationale is complex. Basically, we must
 * address the UX concern that we must provide feedback that an invalid code was applied, so we must
 * trigger an error. However, some codes, such as shipping codes, can be early applied. Some codes,
 * such as shipping codes, can apply but have no cart level applications or line level allocations,
 * but still be applied, and still be valid, even though the code is not actually affecting the
 * estimated total. We do not want to treat that as an error.
 *
 * For a shipping discount that is applicable, even though it has no effect, it will appear in the
 * discount_codes array with applicable true.
 *
 * For a fictional discount that is inapplicable, it will appear in the discount_codes array with
 * applicable false.
 */
function validateCodesAfterUpdate(cart: ajax.Cart, newCodes: string[]) {
  const updatedNormalCodes = getApplicableDiscountCodes(cart);

  // Downstream analytics sometimes does a breakdown on the error message. Input new codes are in
  // mixed cases, leading to nearly duplicate messages. To make it easier on analysis, we report
  // on the normalized discount code, not the actual characters entered by visitors.

  for (const newCode of newCodes) {
    const upperNewCode = newCode.toUpperCase();
    if (!updatedNormalCodes.includes(upperNewCode)) {
      const error: any = new Error(`The discount code ${upperNewCode} was not applied`);
      error.discount_code = upperNewCode;
      error.discount_codes = newCodes;
      throw error;
    }
  }
}
