import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders an add to cart button. It relies on a `<product-element>` slot element.
 */
class AddToCart extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-full',
      'data-update'
    ];
  }

  readonly dataset!: {
    /**
     * If a product is on backorder but can still purchased, it will have messaging stating the date
     * the product will be shipped. This attribute is set using the backorder string of a product's
     * metafield containing the text explaining the actual ship date. This messaging is displayed as
     * needed to inform customers on product detail pages and when added to a user's shopping cart.
     */
    backorder: string;

    /**
     * Override the button text.
     */
    buttonText: string;

    /**
     * Country ISO code.
     *
     * Required.
     *
     * The country code is relayed to child elements that need a country specified. You probably
     * want to use Shopify Liquid's localization.country.iso_code to initialize this element from
     * liquid.
     */
    country: string;

    /**
     * Email of currently signed in customer.
     */
    customerEmail: string;

    /**
     * If specified, is passed to `add-to-cart-button` which will attempt to apply the discount code
     * after variants are added.
     */
    discountCode: string;

    /**
     * A boolean flag for rendering `add-to-cart-button` to the full width allowed by the parent
     * container.
     */
    full: 'false' | 'true';

    /**
     * Boolean flag represented as string to not display the backorder message when rendering. The
     * default behavior when not set is to display the backorder message.
     */
    hideBackorderMessage: 'false' | 'true';

    /**
     * A boolean flag for rendering `add-to-cart-notify-me-button` with alternate outline style
     * instead of solid black with white text. The default behavior when not set is to display the
     * standard CTA with a black background and white "Select Option" or "Add to Bag" text.
     */
    outlineBuyButton: 'false' | 'true';

    /**
     * A boolean flag indicating where the element is rendered. Passed "true" if rendered in a list
     * of products and "false" if rendered in PDPs. The default behavior when not set is the product
     * card will assume it is not being rendered on a product listing page and display any backorder
     * message provided the hideBackorderMessage dataset flag is not set to "true".
     */
    productListingDisplay: 'false' | 'true';

    /**
     * The value of {{ template.name }}, which the Shopify Liquid docs state is "the name of the
     * template's type".
     */
    templateName: string;

    /**
     * Mutate this attribute's value to trigger rendering.
     */
    update: string;

    /**
     * Boolean flag represented as string to display the button in a variant for the upsell modal
     */
    upsellModalVariant: 'false' | 'true';
  };

  public shadowRoot!: ShadowRoot;
  private onProductVariantOptionChangedBound = this.onProductVariantOptionChanged.bind(this);
  private onSlotChangeBound = this.onSlotChange.bind(this);
  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);
  private product?: Product;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }
  }

  public disconnectedCallback() {
    removeEventListener('product-variant-option-changed', this.onProductVariantOptionChangedBound);

    const slot = this.shadowRoot.querySelector<HTMLSlotElement>('slot[name="product"]');
    slot?.removeEventListener('slotchange', this.onSlotChangeBound);

    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (oldValue === newValue) {
      return;
    }

    if (name === 'data-update') {
      this.onUpdateAttributeChanged();
    } else if (name === 'data-full') {
      this.onFullAttributeChanged(newValue);
    }
  }

  private onDOMContentLoaded(_event: Event) {
    this.product = this.scrapeProductInfo();

    addEventListener('product-variant-option-changed',
      this.onProductVariantOptionChangedBound);

    const slot = this.shadowRoot.querySelector<HTMLSlotElement>('slot[name="product"]');
    slot.addEventListener('slotchange', this.onSlotChangeBound);

    this.render();
  }

  private onUpdateAttributeChanged() {
    this.product = this.scrapeProductInfo();

    // these elements are created / removed dynamically. there's no guarantee they exist
    this.shadowRoot.querySelector('add-to-cart-button')?.remove();
    this.shadowRoot.querySelector('add-to-cart-notify-me-button')?.remove();
    this.shadowRoot.querySelector('a')?.remove();
    this.shadowRoot.querySelector('span')?.remove();

    this.render();
  }

  private onFullAttributeChanged(newValue: string) {
    type AddToCartButton = HTMLElementTagNameMap['add-to-cart-button'];
    type AddToCartNotifyMeButton = HTMLElementTagNameMap['add-to-cart-notify-me-button'];
    type LangeButton = AddToCartButton | AddToCartNotifyMeButton;

    const buttons = this.shadowRoot.querySelectorAll<LangeButton>(
      'add-to-cart-button, add-to-cart-notify-me-button');

    const buttonsFull = newValue === 'true' ? 'true' : 'false';
    for (const button of buttons) {
      button.dataset.full = buttonsFull;
    }
  }

  private onSlotChange(_event: Event) {
    // TODO: clarify, at least in a comment, why we do not re-render in this case. is it because we
    // only want to re-render via setting data-update?

    // this event is called when the slot is changed, but also when the element is destroyed
    // the slot may not be filled and the shadowRoot may be empty as well
    // `scrapeProductInfo()` checks for this, but be careful calling methods that assume otherwise
    this.product = this.scrapeProductInfo();
  }

  private onProductVariantOptionChanged(event: WindowEventMap['product-variant-option-changed']) {
    if (event.detail.id === this.product?.id) {
      const productEl = this.querySelector<HTMLElementTagNameMap['product-element']>(
        '[slot="product"]');
      if (!productEl) {
        return;
      }

      productEl.dataset.selectedVariantId = event.detail.selected_variant_id.toString();
      this.product.selected_variant_id = event.detail.selected_variant_id;

      // these elements are created / removed dynamically. there's no guarantee they exist
      this.shadowRoot.querySelector('add-to-cart-button')?.remove();
      this.shadowRoot.querySelector('add-to-cart-notify-me-button')?.remove();
      this.shadowRoot.querySelector('a')?.remove();
      this.shadowRoot.querySelector('span')?.remove();

      this.render();
    }
  }

  private renderWhenProductIsUnavailable(selectedVariant: Variant) {
    // We may be re-rendering so we must check if the element exists. This prevents appending
    // multiple buttons.

    // TODO: there is a bunch of duplicate code here and some of it is inconsistent. add helpers
    // such as "setAddToCartNotifyMeButtonState(element)" that consistently sets the properties
    // of an element regardless of whether it is a new or existing element.

    let element = this.shadowRoot.querySelector('add-to-cart-notify-me-button');
    if (element) {
      element.dataset.productUrl = this.product.url;
      element.dataset.productId = this.product.id.toString();
      element.dataset.productTitle = this.product.title;
      element.dataset.sku = selectedVariant.sku;
      element.dataset.variantId = selectedVariant.id.toString();
      element.dataset.variantTitle = selectedVariant.title;
      element.dataset.full = this.dataset.full === 'true' ? 'true' : 'false';

      if (this.dataset.outlineBuyButton === 'true') {
        element.classList.add('outline');
      }
    } else {
      element = document.createElement('add-to-cart-notify-me-button');

      // If we know the email address, inform the out of stock button about it so that it can
      // pre-fill the email address in its input form.
      if (this.dataset.customerEmail) {
        element.dataset.email = this.dataset.customerEmail;
      }

      element.dataset.productUrl = this.product.url;
      element.dataset.productId = this.product.id.toString();
      element.dataset.productTitle = this.product.title;
      element.dataset.sku = selectedVariant.sku;
      element.dataset.variantId = selectedVariant.id.toString();
      element.dataset.variantTitle = selectedVariant.title;
      element.dataset.full = this.dataset.full === 'true' ? 'true' : 'false';

      if (this.dataset.outlineBuyButton === 'true') {
        element.classList.add('outline');
      }

      this.shadowRoot.appendChild(element);
    }
  }

  private renderWhenProductIsAvailable(selectedVariant: Variant) {
    const variantDataEl = document.createElement('variant-element');
    variantDataEl.setAttribute('slot', 'add-to-cart-button-data-variant');
    variantDataEl.dataset.id = selectedVariant.id.toString();
    variantDataEl.dataset.quantity = '1';

    for (const attribute of selectedVariant.custom_attributes) {
      const variantAttributeEl = document.createElement('custom-attribute');
      variantAttributeEl.dataset.key = attribute.key;
      variantAttributeEl.dataset.value = attribute.value;
      variantDataEl.appendChild(variantAttributeEl);
    }

    // We may be re-rendering. Look for the existing button. We expect to only find one if we are
    // re-rendering. If the button exists, update its properties. If the button does not exist,
    // create it, set its properties for the first time, and append it.

    let addToCartButton = this.shadowRoot.querySelector('add-to-cart-button');

    if (addToCartButton) {
      addToCartButton.dataset.variantTitle = selectedVariant.title === 'Default Title' ?
        this.product.title : `${this.product.title} ${selectedVariant.title}`;
      addToCartButton.dataset.buttonText = this.dataset.buttonText || 'Add to Bag';
      addToCartButton.dataset.full = this.dataset.full === 'true' ? 'true' : 'false';
      addToCartButton.dataset.discountCode = this.dataset.discountCode;
      addToCartButton.dataset.upsellModalVariant = this.dataset.upsellModalVariant === 'true' ?
        'true' :
        'false';

      if (this.dataset.outlineBuyButton === 'true') {
        addToCartButton.classList.add('outline');
      }

      const variantEl = addToCartButton.querySelector('variant-element');
      addToCartButton.replaceChild(variantDataEl, variantEl);
      addToCartButton.dataset.update = Date.now().toString();
    } else {
      addToCartButton = document.createElement('add-to-cart-button');
      addToCartButton.dataset.country = this.dataset.country;
      addToCartButton.dataset.templateName = this.dataset.templateName;
      addToCartButton.dataset.variantTitle = selectedVariant.title === 'Default Title' ?
        this.product.title : `${this.product.title} ${selectedVariant.title}`;
      addToCartButton.dataset.buttonText = this.dataset.buttonText || 'Add to Bag';
      addToCartButton.dataset.full = this.dataset.full === 'true' ? 'true' : 'false';
      addToCartButton.dataset.discountCode = this.dataset.discountCode;
      addToCartButton.dataset.update = Date.now().toString();
      addToCartButton.dataset.upsellModalVariant =
        this.dataset.upsellModalVariant === 'true' ? 'true' : 'false';
      addToCartButton.appendChild(variantDataEl);

      if (this.dataset.outlineBuyButton === 'true') {
        addToCartButton.classList.add('outline');
      }

      this.shadowRoot.appendChild(addToCartButton);
    }
  }

  private shouldRenderBackorderMessage(selectedVariant: Variant) {
    if (!selectedVariant.backorder_message) {
      return false;
    }

    if (this.dataset.hideBackorderMessage === 'true') {
      return false;
    }

    return !(
      this.dataset.productListingDisplay === 'true' &&
      selectedVariant.hide_backorder_in_collection
    );
  }

  private renderBackorderMessage(selectedVariant: Variant) {
    // TODO: do not forget to apply fix of checking if already appended to other renders

    let backorderMessageEl = this.shadowRoot.querySelector<HTMLElement>(
      '[data-backorder-message="true"]');
    const shouldAppend = !backorderMessageEl;

    if (this.dataset.productListingDisplay === 'true') {
      if (!backorderMessageEl) {
        backorderMessageEl = document.createElement('a');
        backorderMessageEl.dataset.backorderMessage = 'true';
      }

      backorderMessageEl.textContent = 'On backorder, see ship date';
      backorderMessageEl.setAttribute('href', `${this.product.url}?variant=${selectedVariant.id}`);
    } else {
      if (!backorderMessageEl) {
        backorderMessageEl = document.createElement('span');
        backorderMessageEl.dataset.backorderMessage = 'true';
      }

      backorderMessageEl.textContent = selectedVariant.backorder_message;
    }

    if (shouldAppend) {
      backorderMessageEl.classList.add('add-to-cart-info-warning');
      this.shadowRoot.prepend(backorderMessageEl);
    }
  }

  /**
   * Renders the product associated with this element. The type of the product fundamentally changes
   * the meaning of its properties. In the case of bundles, the variants of the product are actually
   * products of their own, and the meanings of being out of stock or backordered change.
   *
   * We also distinguish between a product that has only one variant and one that has many. We call
   * a single variant product a simple product and a many variant product a configurable product.
   *
   * @todo the code that prematurely appends should wait to append until slots are set
   */
  private render() {
    if (!this.product) {
      return;
    }

    // TODO: there is a rare error in Sentry "Cannot read properties of undefined (reading 'sku')"
    // that is happening because of the code `this.product.variants[0].sku` below. Capture more info
    // about this state so that we can investigate further. This function is only ever called from
    // render, which checks that this.product is defined, so the issue is understanding the reason
    // for why the variants array is empty at the time this function runs. It is probably because of
    // the issue with this.products being initialized by the connected callback which we know is
    // unable to reliably access its dom subtree at that time but we are assuming that it can
    // reliably do so because this code was implemented without understanding the nuanced issues of
    // what operations can happen at the time of the connected callback.

    if (!Array.isArray(this.product.variants)) {
      return;
    }

    let selectedVariant = this.product.variants.find(variant =>
      variant?.id === this.product.selected_variant_id);

    // If we are unable to determine which variant is selected, fall back to whatever is the first
    // variant in the array.

    if (!selectedVariant && this.product.variants.length > 0) {
      selectedVariant = this.product.variants[0];
    }

    // TODO: this should never happen, investigate this bug

    if (!selectedVariant) {
      return;
    }

    if (!selectedVariant.available) {
      this.renderWhenProductIsUnavailable(selectedVariant);
      return;
    }

    this.renderWhenProductIsAvailable(selectedVariant);

    if (this.shouldRenderBackorderMessage(selectedVariant)) {
      this.renderBackorderMessage(selectedVariant);
    }
  }

  /**
   * Creates and returns a {@link Product} object that represents the product associated with this
   * element at this time. The state of the product is spread out over the attributes of this
   * element and its descendant elements, some of which come in through slots.
   */
  private scrapeProductInfo() {
    type ProductElement = HTMLElementTagNameMap['product-element'];
    const productElement = this.querySelector<ProductElement>('[slot="product"]');
    if (!productElement) {
      return;
    }

    const product = <Product>{};
    product.available = productElement.dataset.available === 'true';
    product.id = parseInt(productElement.dataset.id, 10);
    product.url = productElement.dataset.url;
    product.selected_variant_id = parseInt(productElement.dataset.selectedVariantId, 10);
    product.title = productElement.dataset.title;
    product.tags = productElement.dataset.tags;
    product.type = productElement.dataset.type;
    product.variants = [];

    type VariantElement = HTMLElementTagNameMap['variant-element'];

    const variants = productElement.querySelectorAll<VariantElement>('variant-element[data-id]');
    for (const variantElement of variants) {
      const variant = <Variant>{};
      variant.id = parseInt(variantElement.dataset.id, 10);
      variant.available = variantElement.dataset.available === 'true';
      variant.backorder_message = variantElement.dataset.backorder;
      variant.hide_backorder_in_collection = variantElement.dataset.hideBackorderInCollection;
      variant.custom_attributes = [];
      variant.sku = variantElement.dataset.sku;
      variant.title = variantElement.dataset.title;

      const attributes = variantElement.querySelectorAll('custom-attribute');
      for (const attributeElement of attributes) {
        variant.custom_attributes.push({
          key: attributeElement.dataset.key,
          value: attributeElement.dataset.value
        });
      }

      product.variants.push(variant);
    }

    return product;
  }
}

/**
 * Represents a Shopify product and its variants.
 */
interface Product {
  /**
   * Whether the product has inventory in stock and is available for sale.
   */
  available: boolean;

  /**
   * Product id.
   */
  id: number;

  /**
   * Represents whether a bundled item in a value set is the tool
   *
   * @todo if this is truly no longer in use it should be removed
   *
   * @deprecated
   */
  is_value_set_tool?: boolean;

  /**
   * The id of the selected variant of the product.
   */
  selected_variant_id: number;

  /**
   * The tags tied to the product.
   */
  tags: string;

  /**
   * The original product title (not metafields title).
   */
  title: string;

  /**
   * The custom type of the product.
   *
   * @example "bundle"
   */
  type: string;

  /**
   * The url of the product details page for the product.
   */
  url: string;

  /**
   * One or more product variants.
   */
  variants: Variant[];
}

/**
 * Represents a Shopify variant
 */
interface Variant {
  /**
   * Whether the variant has inventory in stock and is available for sale.
   */
  available: boolean;

  backorder_message: string;

  custom_attributes: {
    /**
     * Key or name of the attribute.
     */
    key: string;

    /**
     * Value of the attribute.
     */
    value: string;
  }[];

  hide_backorder_in_collection: string;

  /**
   * Variant id
   */
  id: number;

  price: number;

  sku: string;

  title: string;
}

declare global {
  interface HTMLElementTagNameMap {
    'add-to-cart': AddToCart;
  }
}

if (!customElements.get('add-to-cart')) {
  customElements.define('add-to-cart', AddToCart);
}
